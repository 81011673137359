<template lang="html">
    <div class="h-full flex flex-col overflow-hidden">
		<!-- h-full  -->
        <div 
			v-if="loading == 'fetching'"
            class="h-full flex items-center justify-center">
            
            <div class="spinner"></div>
        </div>
		<div 
            v-else-if="departmentUsers"
            class="
				h-full
				overflow-auto
            ">
			
            <div 
				class="
					grid
					gap-6 
					overflow-auto
					p-8
				">
				
				<UserCard
					v-for="dU in departmentUsers"
					:key="'departmentUser_'+dU.username"
					:userPassed="dU">
					
					<div 
						v-if="dU.departments != 'admin'"
						class="flex-none text-xl mr-4 mt-4">
						
						<div 
							v-if="!loading"
							class="flex-none text-xl text-beige-darker ml-4 w-8 h-8 leading-relaxed text-center "
							@click="onRemoveUser(dU)">
							
							<i class="fas fa-times "/>
						</div>
					</div>
				</UserCard>
				
				<template v-if="departmentUsersNotParticipating && departmentUsersNotParticipating.length > 0">
					<h3>Legg til brukere til avdelingen</h3>
					
					<UserCard
						v-for="u in departmentUsersNotParticipating"
						:key="'user_'+u.username"
						:userPassed="u">
						
						<div class="flex-none text-xl mr-4 mt-4">
							<div 
								v-if="loading && loading == 'updating'"
								class="spinner2"
							/>
							<button 
								v-else
								type="submit"
								@click="onAddUser(u)">
								
								<i class="fas fa-plus bg-green text-white w-8 h-8 leading-relaxed text-center rounded" />
							</button>
						</div>
					</UserCard>
				</template>
				
			</div>
            
        </div>
        <div 
            v-else
            class="p-8 text-center">
            
            <div class="">
                Ingen brukere er gitt tilgang
            </div>
            <router-link 
                :to="{ name: 'access'}"
                class="link">
                Administrer brukertilgang
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        'UserCard': () => import('@/components/users/UserCard.vue'),
    },
    
    props: { 
        department: {
            type: Object,
            required: true,
        }
    },
    
    computed:{
        users(){
            return this.$store.getters.getUsers;
        },
        
        departmentUsers(){
            if (!this.users || !this.users.length) {
                return
            }
            
            return this.users.filter( u => {
                if ( 
                    ( u.departments && u.departments.includes( this.department.departmentId ) ) ||
                    u.departments == 'admin' ){
                    
                    return true;
                } 
            });
        },
        
        departmentUsersNotParticipating(){
            if (!this.users || !this.users.length) {
                return
            }
            
            return this.users.filter( u => !this.departmentUsers.find( dU => dU.username == u.username) );
        },
    },
    
    data(){
        return {
            loading: null,
        }
    },
    
    methods: {
        async onAddUser( user ){
            // console.log('onAddUser');
            
            let userChanged = {
                ...user,
                departments: [
                    ...user.departments,
                    this.department.departmentId
                ],
            }
            
            await this.onUpdateUser( userChanged );
        },
        
        async onRemoveUser( user ){
            // console.log('onRemoveUser');
            if (! confirm('Er du sikker på at du vil fjerne brukeren fra avdelingen?')) return; 
            
            let userChanged = {
                ...user,
                departments: user.departments.filter( d => d != this.department.departmentId),
            }
            
            await this.onUpdateUser( userChanged );
        },
        
        async onUpdateUser( user ){
            try {
                this.loading = 'updating';
                const updateUserResponse = await this.$store.dispatch('updateUser', user);
                await this.$store.dispatch('listUsers');
                
                this.$notify({ type: 'success', text: 'Endringen ble lagret' });
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    text: e.message || 'Kunne ikke lagre endringen'
                });
                
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
    },
    
    async mounted(){
        this.loading = 'fetching';
        await this.$store.dispatch('listUsers');
        this.loading = null;
    }
}
</script>

<style lang="css" scoped>
</style>
